$(function() {
  AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
  });
});

$(document).ready(function(){
    $("a.fancybox, .gallery-item .gallery-icon a").attr('data-fancybox','postsgallery').fancybox({
        'overlayOpacity' : 0.1,
        'overlayColor' : '#111',
        'hideOnOverlayClick' : 'true',
        'enableEscapeButton' : 'true',
        'showCloseButton' : false,
        'titleShow' : 'false',
        'showNavArrows' : true
    });
});
